import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Axios from 'axios';

function Login() {
    const navigate = useNavigate();
    const [email , setemail] = useState("");
    const [password , setpassword] = useState("");
    const [loginStatus , setloginStatus] = useState("");

    Axios.defaults.withCredentials = true;

    const login = (e) => {
        e.preventDefault();
        if((email == "danyal@gmail.com" && password == "danyal12345") || (email == "test@gmail.com" && password == "test12345") 
        || (email == "admin@gmail.com" && password == "admin12345")){
            navigate('/app-welcome',{ replace: true });
        }else{
            alert("Invalid Details")
        }
    };

    // const login = (e) => {
    //     e.preventDefault();
    //     Axios.post("http://localhost:3001/login",{
    //         email : email,
    //         password : password,
    //     }).then((response) => {
    //         // console.log(response);
    //         console.log(response.data.user)
    //         if(response.data.message){
    //             setloginStatus(response.data.message);
    //         }else if(response.data.err){
    //             console.log('Invalid Request');
    //         }else{
    //             console.log("Hi")
    //             navigate('/app-welcome',{ replace: true });
    //             //setloginStatus(response.data[0].email);
    //         }
    //     });
    // };

    useEffect(() => {
        Axios.get("http://localhost:3001/login").then((response) => {
            //console.log(response);
            if (response.data.loggedIn === true) {
                navigate('/app-welcome',{ replace: true });
                //setloginStatus(response.data.user[0].email);
            }
        })
    },[])

  return (
    <>
        <div className="fixed-background"></div>
        <main>
            <div className="container mt-5">
                <div className="row h-100">
                    <div className="col-12 col-md-10 mx-auto my-auto">
                        <div className="card auth-card">
                            <div className="position-relative image-side ">
                                <p className=" text-white h2">MAGIC IS IN THE DETAILS</p>
                                <p className="white mb-0">
                                    Please use your credentials to Login.
                                    <br />If you are not a member, please 
                                    <Link to="/register" className="white"> Register</Link>.
                                </p>
                            </div>
                            <div className="form-side">
                                <a href="#">
                                    <span className="logo-single" />
                                </a>
                                <h6 className="mb-4">Login</h6>
                                <form>
                                    <label className="form-group has-float-label mb-4">
                                        <input className="form-control" type="text" onChange={(e) => {setemail(e.target.value)}} />
                                        <span>E-mail</span>
                                    </label>
                                    <label className="form-group has-float-label mb-4">
                                        <input className="form-control" type="password" onChange={(e) => {setpassword(e.target.value)}}/>
                                        <span>Password</span>
                                    </label>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <Link to="/forget">Forget password?</Link>
                                        <button className="btn btn-primary btn-lg btn-shadow" type="submit" onClick={login}>LOGIN</button>
                                    </div>
                                    {/* <h1>{loginStatus}</h1> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </>
  );
}

export default Login;
