import React from 'react'
import data from '../MOCK_DATA.json'
import {Link} from 'react-router-dom'

export default function YoutubePopularChannels() {
    let searchHandle;
    return (
        <>
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 list" data-check-all="checkAll">
                    {
                    data.map((val, key) => {
                    {
                        searchHandle = val.handle.split("@");
                    }
                    return <div className="card d-flex flex-row mb-3" key={key}>
                        <img src={val.profile_picture_url} alt="Profile Picture" className="list-thumbnail responsive border-0 card-img-left" />
                        <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                            <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                                <a href={"https://www.instagram.com/"+searchHandle[1]} className="w-40 w-sm-100" target="_blank">
                                    <p className="list-item-heading mb-0 truncate">{val.handle}</p>
                                </a>
                                <span>{val.name}</span>
                                <p className="mb-0 text-muted text-small w-15 w-sm-100">{val.followers_count}</p>
                                <p className="mb-0 text-muted text-small w-15 w-sm-100">{val.media_count}</p>
                                <div className="w-15 w-sm-100">
                                    <Link to={`/profile/${searchHandle[1]}`}  className="btn btn-primary">Analyze</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    })
                    }
                </div>
            </div>
            </div>
        </>
    )
}
