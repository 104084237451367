import React, {useState , useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons'
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons'
import Axios from 'axios';
import Posts from './Posts';

export default function InstaMainCard(params) {
    const [userResult, setUserResult] = useState([])
    const [comments, setcommentsCount] = useState("")
    const [likes, setlikesCount] = useState("")
    let commentsCount = 0;
    let likesCount = 0;
    const username = params.userName
    //For data
    useEffect(() => {
        let isCancelled = true
        Axios.get("https://graph.facebook.com/v14.0/17841455177179565?fields=business_discovery.username("+username+"){id,follows_count,biography,profile_picture_url,name,followers_count,media_count,media{media_url,comments_count,like_count}}&access_token=EAAK6cZBYX3xEBAIZAIuhPK3DCRs7X6k2XPZBan1WLZAvlfZAXK5IZAAwdzZAwZCJVZCiiZBKAdfXkUbdZBSusEZCaQO9xOLiZBC4AW5Tpye4P6F5dpjK8ihrhk6O3RKZBXupbOELPi9mQZCEk7tZCG2bgd42jvmoRp5atgW697hZAwW0ZBltEghqv2EZBfhEwTC3z8VL9wpx2TemmYrOF9VEXDJ9FwYZARK3")
        .then((response) => {
            console.log(response.data.business_discovery.media)
            // console.log(response.data.business_discovery.media.data.length)
            for(let i = 0 ; i < response.data.business_discovery.media.data.length ; i++){
                commentsCount += response.data.business_discovery.media.data[i]["comments_count"];
                likesCount += response.data.business_discovery.media.data[i]["like_count"];
            }
            setcommentsCount(commentsCount/response.data.business_discovery.media.data.length)
            setlikesCount(likesCount/response.data.business_discovery.media.data.length)            
            if(isCancelled){
                setUserResult(response.data.business_discovery)
            } 
        }).catch((err) => {
            console.log(err);
        });
        return () => {
            console.log("cancelled")
            isCancelled = false
        }
    }, [username]);

    //For unit 
    function getNumberUnit(num, round = 1) {
        const unit = Math.floor(Math.round(num / 1.0e+1).toLocaleString().replaceAll(',', '').length),
            wunit = ["K", "M", "B", "T", "Q"][Math.floor(unit / 3) - 1],
            funit = Math.abs(Number(num)) / Number('1.0e+' + (unit - unit % 3));
        return wunit ? funit.toFixed(round).toLocaleString() + ' ' + wunit : num.toFixed(round).toString();
    }
    
  return (
  <>
    {/* Main Card */}
    <div className="row mb-4">
        <div className="col-12">
            <div className="card bg-dark text-light">
                <div className="card-body">
                    <div className="row">
                        <div className="col-3">
                            <img alt="Profile" src={userResult.profile_picture_url} className="img-thumbnail border-0 rounded-circle list-thumbnail" width="320" height="320" />
                        </div>
                        <div className="col-5">
                            <h1>{userResult.name}</h1> <br />
                            <p>@{username}</p>
                        </div>
                        <div className="col-4 align-middle">
                            <a className='btn btn-outline-light' href="">ADD TO LIST</a>
                            <a className='btn btn-outline-light ml-3' href="">DOWNLOAD REPORT</a>
                        </div>
                    </div>
                    <div className="card-footer bg-dark">
                        <div className="row">
                            <div className="col-5">
                                <a href={"https://www.instagram.com/"+username} target="_blank" className='text-light'>
                                    <FontAwesomeIcon icon={faInstagram} size="2x"></FontAwesomeIcon>
                                    <br/>
                                    <span>{getNumberUnit(userResult.followers_count)} followers</span>
                                </a>
                            </div>
                            <div className="col-1">
                                <span>|</span><br/>
                                <span>|</span>
                            </div>
                            <div className="col-5">
                                <a href={"https://www.tiktok.com/"+username} target="_blank" className='text-light'>
                                    <FontAwesomeIcon icon={faTiktok} size="2x"></FontAwesomeIcon>
                                    <br/>
                                    <span>TikTok channel</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Main Card */}
    {/* Insights */}
    <div>
        <h1><b>Influencer insight</b></h1>
        <div className="row mb-4">
            <div className="col-6">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                            <h2><b>{getNumberUnit(userResult.followers_count)}</b></h2>
                            <p>Followers</p>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                            <h2><b></b></h2>
                            <p>Followers Credibility <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-6" >
                <div className="card">
                    <div className="card-body">
                        <h6><b>Followers Breakdown</b></h6>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat, velit! Asperiores sint et cupiditate amet quas, eligendi rerum sequi! Quas, atque rem! Vel, ut delectus magni debitis facere quidem. Fuga.</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat, velit! Asperiores sint et cupiditate amet quas, eligendi rerum sequi! Quas, atque rem! Vel, ut delectus magni debitis facere quidem. Fuga.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Insights */}
    {/* Data regarding psots */}
    <div>
        <h1><b>Post Data</b></h1>
        <div className="row mb-4">
            <div className="col-8">
                <div className="row">
                    <div className="col-6">
                        <div className="card">
                            <div className="card-body">
                                <h2><b>{likes}</b></h2>
                                <p>Avg Likes <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <div className="card-body">
                                <h2><b>{comments}</b></h2>
                                <p>Avg Comments <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></p>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div className="row">
                <div className="col-6">
                    <div className="card">
                        <div className="card-body">
                            <h2><b></b></h2>
                            <p>Avg post Impressions</p>
                        </div>
                    </div>
                </div>
                    <div className="col-6">
                        <div className="card">
                            <div className="card-body">
                                <h2><b></b></h2>
                                <p>Avg Post video Views <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="card">
                    <div className="card-body">
                        <h2><b></b></h2>
                        <p>Engagement rate <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></p>
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Perferendis eligendi, laudantium modi eveniet, nihil ipsam aspernatur, quis excepturi quidem delectus praesentium. Laudantium ullam sapiente facere consequuntur amet et possimus dolores.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="row mb-4">
            <div className="col-4">
                <div className="card">
                    <div className="card-body">
                        <h2><b></b></h2>
                        <p>Post price <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></p>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="card">
                    <div className="card-body">
                        <h2><b></b></h2>
                        <p>CPE <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></p>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="card">
                    <div className="card-body">
                        <h2><b></b></h2>
                        <p>CPM <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Data regarding psots */}
    {/* Story Data */}
    <div>
        <h1><b>Story Data</b></h1>
        <div className="row mb-4">
            <div className="col-4">
                <div className="card">
                    <div className="card-body">
                        <h2><b></b></h2>
                        <p>Avg story views <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></p>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="card">
                    <div className="card-body">
                        <h2><b></b></h2>
                        <p>Story views rate <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></p>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="card">
                    <div className="card-body">
                        <h2><b></b></h2>
                        <p>Link clicks <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></p>
                    </div>
                </div>
            </div>
        </div>

        <div className="row mb-4">
            <div className="col-4">
                <div className="card">
                    <div className="card-body">
                        <h2><b></b></h2>
                        <p>Story engagement rate <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></p>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="card">
                    <div className="card-body">
                        <h2><b></b></h2>
                        <p>Story price <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></p>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="card">
                    <div className="card-body">
                        <h2><b></b></h2>
                        <p>CPV <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></p>
                    </div>
                </div>
            </div>
        </div>

        <div className="row mb-4">
            <div className="col-6">
                <div className="card">
                    <div className="card-body">
                        <h2><b></b></h2>
                        <p>CPE <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></p>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="card">
                    <div className="card-body">
                        <h2><b></b></h2>
                        <p>CPC <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Story Data */}

    {/* Reels Data */}
    <div>
        <h1><b>Reels Data</b></h1>
        <div className="row mb-4">
            <div className="col-4">
                <div className="card">
                    <div className="card-body">
                        <h2><b></b></h2>
                        <p>Avg reels plays <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></p>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="card">
                    <div className="card-body">
                        <h2><b></b></h2>
                        <p>Reels engagements <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></p>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="card">
                    <div className="card-body">
                        <h2><b></b></h2>
                        <p>Reels views engagement rate <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></p>
                    </div>
                </div>
            </div>
        </div>

        <div className="row mb-4">
            <div className="col-4">
                <div className="card">
                    <div className="card-body">
                        <h2><b></b></h2>
                        <p>Reels price <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></p>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="card">
                    <div className="card-body">
                        <h2><b></b></h2>
                        <p>CPP <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></p>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="card">
                    <div className="card-body">
                        <h2><b></b></h2>
                        <p>CPE <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Reels Data */}

    {/* Score */}
    <div>
        <div className="row mb-4">
            <div className="col-12">
                <div className="card bg-dark text-light">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-10">
                                <h1><b>SCORE</b></h1> <br />
                                <h4><b>Pros &#x26; Cons</b></h4>
                                <ul style={{ display: "inline-block" }}>
                                    <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
                                    <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
                                    <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
                                </ul>
                                <ul style={{ display: "inline-block" }}>
                                    <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
                                    <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
                                    <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
                                </ul>
                            </div>
                        <div className="col-2">
                            <div className="card text-dark text-center">
                                <div className="card-body">
                                    <h4><b>Score <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></b></h4>
                                    <hr/>
                                    <span style={{ fontSize: "50px" }}><b>100</b></span><br/>
                                    <span><b>Excellent</b></span>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Score */}

    {/* Topic Tensors & Lookalikes */}
    <div>
        <div className="row mb-4">
            <div className="col-6">
                <div className="card">
                    <div className="card-body">
                        <h6><b>Topic Tensors</b></h6>
                        <p></p>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="card">
                    <div className="card-body">
                        <h6><b>Lookalikes (similar topics)</b></h6>
                        <p></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Topic Tensors & Lookalikes */}

    {/* Graphs For Followers*/}
    <div>
        <div className="row mb-4">
            <div className="col-4">
                <div className="card">
                    <div className="card-body">
                        <h6><b>Followers 0.00% this month</b></h6>
                        <p></p>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="card">
                    <div className="card-body">
                        <h6><b>Following 0.00% this month</b></h6>
                        <p></p>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="card">
                    <div className="card-body">
                        <h6><b>Likes 0.00% this month</b></h6>
                        <p></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Graphs For Followers*/}

    {/* Graphs For Engagements*/}
    <div>
        <div className="row mb-4">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <h6><b>Engagements spread for last posts</b> <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></h6>
                        <p></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Graphs For Engagements*/}

    {/* Popular # & @*/}
    <div>
        <div className="row mb-4">
            <div className="col-6">
                <div className="card">
                    <div className="card-body">
                        <h6><b>Popular #</b> <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></h6>
                        <p></p>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="card">
                    <div className="card-body">
                        <h6><b>Popular @</b> <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></h6>
                        <p></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Popular # & @*/}

    {/* Influencer interests */}
    <div>
        <div className="row mb-4">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <h6><b>Influencer interests</b> <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></h6>
                        <p></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Influencer interests */}

    {/* Audience data */}
    <div>
        <div className="row mb-4">
            <div className="col-4">
                <div className="card">
                    <div className="card-body">
                        <h6><b>Gender split</b> <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></h6>
                        <p></p>
                    </div>
                </div>
            </div>
            <div className="col-8">
                <div className="card">
                    <div className="card-body">
                        <h6><b>Age and gender split</b> <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></h6>
                        <p></p>
                    </div>
                </div>
            </div>
        </div>
        {/* City Wise */}
        <div className="row mb-4">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <h6><b>Location by city</b> <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></h6>
                        <p></p>
                    </div>
                </div>
            </div>
        </div>
        {/* By Country & Language */}
        <div className="row mb-4">
            <div className="col-4">
                <div className="card">
                    <div className="card-body">
                        <h6><b>Location by country</b> <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></h6>
                        <p></p>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="card">
                    <div className="card-body">
                        <h6><b>Ethnicity</b> <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></h6>
                        <p></p>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="card">
                    <div className="card-body">
                        <h6><b>Language</b> <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></h6>
                        <p></p>
                    </div>
                </div>
            </div>
        </div>
        {/* Audience which are Influencers */}
        <div className="row mb-4">
            <div className="col-6">
                <div className="card">
                    <div className="card-body">
                        <h6><b>Influencers</b> <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></h6>
                        <p></p>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="card">
                    <div className="card-body">
                        <h6><b>Audience reachability</b> <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></h6>
                        <p></p>
                    </div>
                </div>
            </div>
        </div>
        {/* Audience Brands */}
        <div className="row mb-4">
            <div className="col-6">
                <div className="card">
                    <div className="card-body">
                        <h6><b>Audience brand affinity</b> <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></h6>
                        <p></p>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="card">
                    <div className="card-body">
                        <h6><b>Audience interest affinity</b> <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></h6>
                        <p></p>
                    </div>
                </div>
            </div>
        </div>
        {/* Audience Lookalikes & Influencers */}
        <div className="row mb-4">
            <div className="col-6">
                <div className="card">
                    <div className="card-body">
                        <h6><b>Audience lookalikes</b> <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></h6>
                        <p></p>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="card">
                    <div className="card-body">
                        <h6><b>Influencers</b> <FontAwesomeIcon icon={faCircleQuestion} size="lg"></FontAwesomeIcon></h6>
                        <p></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Audience data */}

    {/* Posts */}
    <div>
        <div style={{display: "flex", justifyContent: "space-between"}}>
            <h1 style={{margin: "0px", padding: "0px"}}>Posts</h1>
            <div style={{display: "inline-block"}}>
                <button style={{marginRight : "3px"}} className="btn btn-primary">Popular</button>
                <button className="btn btn-primary">Sponsored</button>
            </div>
        </div>
        <div className="row mb-4">
            <Posts posts={userResult?.media?.data}></Posts>
        </div>
    </div>
    {/* Posts */}
  </>
  )
}
