import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom'
import Axios from 'axios';
import Select from 'react-select';

export default function Search(props) {
  const options = [
    { value: 'instagram', label: 'Instagram', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Instagram_logo_2022.svg/2048px-Instagram_logo_2022.svg.png' },
    { value: 'youtube', label: 'Youtube', image: 'https://www.freepnglogos.com/uploads/youtube-play-red-logo-png-transparent-background-6.png'},
    { value: 'tiktok', label: 'Tiktok', image: 'https://www.freepnglogos.com/uploads/tik-tok-logo-png/tik-tok-tiktok-logo-app-trend-1.png'},
    { value: 'twitch', label: 'Twitch', image: 'https://www.freepnglogos.com/uploads/twitch-app-logo-png-3.png'}
  ];
  const [searchData , setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState('instagram');
  let searchHandle;
  let cancelToken;

  const clickHandler = async (e) => {
    let search = e.target.value;
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Canceling Request");
    }
    cancelToken = Axios.CancelToken.source();
    console.log(e.target.value);
    const result = await Axios.get(
      "http://localhost/apis/scrapper/search_handles.php?search="+ search,
      { cancelToken: cancelToken.token }
    ).then((response) => {
      console.log(response.data);
      setData(response.data);
    }).catch((err) => {
      console.log(err);
    });
  }

  // handle onChange event of the dropdown
  const handleChange = e => {
    setSelectedValue(e.value);
  }

  let parrentPage = 'Home';
  let currentPage = 'Search';
  return (
    <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h1>Influencer Search</h1>
              <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
                <ol className="breadcrumb pt-0">
                  <li className="breadcrumb-item">
                    <a href="#">{parrentPage}</a>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <a href="#">{currentPage}</a>
                  </li>
                </ol>
              </nav>

              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12 col-md-3 col-lg-3 mb-3">
                    <Select
                      onChange={handleChange}
                      defaultValue={options[0]}
                      options={options}
                      formatOptionLabel={option => (
                        <div className="platform-option">
                          <img width={20} src={option.image} alt="platform-image" />
                          <span>{option.label}</span>
                        </div>
                      )}
                    />
                    Select : {selectedValue}
                    </div>
                    <div className="col-sm-12 col-md-7 col-lg-7 mb-3">
                      <input type="text" className='form-control' value={props.value} onChange={clickHandler} placeholder='Influencer profile URL, user ID or @handle'/>
                      <ul>
                      {searchData != "nodata" &&
                      searchData.map((val, key) => {
                        {
                          searchHandle = val.handle.split("@");
                        }
                        return <Link to={`/profile/${searchHandle[1]}`}><li style={{listStyleType: "none",border: "1px #898e94 solid", borderRadius : "5px"}}>
                          <img src={val.profile_picture_url} width={42} height={42} style={{borderRadius: "50%"}} alt='Profile Pic'/>
                          <div style={{display: "inline-block"}}>
                            <span style={{display: "block", whiteSpace: "nowrap", overFlow: "hidden", textOverflow: "ellipsis"}}>{val.handle}</span>
                            <span style={{display: "block", whiteSpace: "nowrap", overFlow: "hidden", textOverflow: "ellipsis", color :"#898e94" }}>{val.name}</span>
                          </div>
                          <span style={{marginLeft: "100px"}}>{val.followers_count}</span>
                        </li>
                        </Link>
                      })
                      }
                      </ul>
                    </div>
                  </div>
                </div>  
              </div>
            </div>
          </div>
        </div>
    </>
  )
}
