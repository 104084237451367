import React from 'react'
import {useParams} from 'react-router-dom'
import Topnav from '../common/Header';
import Sidebar from '../common/Sidebar';
import Footer from '../common/Footer';
import Search from '../common/Search';
// import data from '../MOCK_DATA.json'
import InstaMainCard from '../common/Profile/InstaMainCard';

export default function Profile() {
  const params = useParams()
  return (
    <>
      <Topnav />
      <Sidebar />
      <main>
        <Search value={params.userName}/>
        <hr></hr>
        <InstaMainCard userName={params.userName}/>
      </main>
      <Footer />
    </>
  )
}
