import React from 'react'

export default function footer() {
    return (
        <footer className="page-footer">
            <div className="footer-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <p className="mb-0 text-muted">2022 © Influsense</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
