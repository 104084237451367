import React from 'react';
import Topnav from '../common/Header';
import Sidebar from '../common/Sidebar';
import Footer from '../common/Footer';

export default function PrivacyPolicy() {
  return (
    <>
      <Topnav />
      <Sidebar />
      <main>
        <h1>Privacy Policy</h1>
        <div className="container-fluid">
          <p>This policy lays down the basic criteria related to our Privacy Policy and how we process your information so please
            take due time out to completely read and understand this policy. </p>
          <p>What information do we collect about you?</p>
          <p>We collect and process information you give us when you create an account and upload content to the Platform.
            This includes technical and behavioral information about your use of the Platform. We also collect information about
            you if you download the app and interact with the Platform without creating an account.</p>
          <p>How will we use the information about you?</p>
          <p>We use your information to provide the Platform to you and to improve and administer it. We use your information
            to, among other things, show you suggestions in the ‘For You’ feed, improve and develop the Platform and ensure
            your safety. Where appropriate, we will also use your personal information to serve you targeted advertising and
            promote the Platform.</p>
          <p>Who do we share your information with?</p>
          <p>We share your data with third party service providers who help us to deliver the Platform, such as cloud storage
            providers. We also share your information with business partners, other companies in the same group as SGL,
            content moderation services, measurement providers, advertisers, and analytics providers. Where and when
            required by law, we will share your information with law enforcement agencies or regulators, and with third parties
            pursuant to a legally binding court order.
          </p>
          <p>How long do we keep hold of your information?</p>
          <p>We retain your information for as long as it is necessary to provide you with the service. Where we do not need your
            information in order to provide the service to you, we retain it only as long as we have a legitimate business purpose
            in keeping such data or where we are subject to a legal obligation to retain the data. We will also retain your data if
            we believe it is or will be necessary for the establishment, exercise or defense of legal claims.
          </p>
          <p>How will we notify you of any changes to this Privacy Policy?</p>
          <p>We will generally notify all users of any material changes to this policy through a notice on our Platform. However,
            you should look at this policy regularly to check for any changes. We will also update the “Last Updated” date at the
            top of this policy, which reflects the effective date of such policy. By accessing or using the Platform, you
            acknowledge that you have read this policy and that you understand your rights in relation to your personal data
            and how we will collect, use and process it.
          </p>
          <p>The types of personal data we use</p>
          <p>We collect and use the following information about you:</p>
          <p><b>Your Profile Information.</b> You give us information when you register on the Platform, including your username,
            password, date of birth (where applicable), email address and/or telephone number, information you disclose in
            your user profile, and your photograph or profile video.</p>
          <p><b>User Content and Behavioral Information.</b> We process the content you generate and view on the Platform, including
            preferences you set (such as choice of language) photographs, audios and videos you upload or create, comments
            and livestreams you make (“<b>User Content</b>”). We collect User Content through pre-loading at the time of creation,
            import, or upload, regardless of whether you choose to save or upload that User Content, in order to recommend
            audio options and provide other personalized recommendations. If you apply an effect to your User Content, we
            may collect a version of your User Content that does not include the effect. We may collect information about the
            images and audio that are a part of your User Content, such as identifying the objects and scenery that appear, the
            existence and location within an image of face and body features and attributes, the nature of the audio, and the
            text of the words spoken in your User Content. We may collect this information to enable special video effects, for
            content moderation, for demographic classification, for content and ad recommendations, and for other nonpersonally-identifying operations. We may access content, including text, images, and video, found in your device’s
            clipboard, with your permission. For example, if you choose to initiate content sharing with a third- party platform,
            or choose to paste content from the clipboard into the <b>INFLUSENSE</b> platform, we access this information stored in
            your clipboard in order to fulfill your request. We collect information through surveys, challenges and competitions
            in which you participate. We also collect information regarding your use of the Platform, e.g., how you engage with
            the Platform, including how you interact with content we show to you, the ads you view, videos you watch and
            problems encountered, the content you like, the content you save to ‘My Favourites’, the users you follow and how
            you engage with mutual followers. We also infer your preferences, including your interests, gender and age for the
            purpose of personalising content. We process information about your followers, the likes you receive and responses
            to content you upload, for the purposes of promoting your content to other users and exploring whether your profile
            presents further opportunities for collaboration. Where appropriate, we will also use this information for the
            purpose of serving personalised advertising and to tell you about new services and opportunities.
          </p>
          <div>
            <p><b>Information from Third Parties.</b> You may choose to share certain data with us from third parties or through your
              use of the Platform, we may collect such third party data automatically. We have set out further detail on the
              information we receive from third parties below:</p>
            <u>Business Partners</u>
            <p>
              If you choose to register or use the Platform using a third-party social network account details (e.g., Facebook,
              Twitter, Instagram, Google) or login service, you will provide us or allow to provide us with your username, public
              profile, and other possible information related to such account. We will likewise share certain information with your
              social network such as your app ID, access token and the referring URL. If you link your <b>INFLUSENSE</b> account to
              another service, we may receive information about your use of that service.</p>
            <u>Advertisers, Advertising Networks, and Analytic Providers</u>
            <p>We use the information collected from you, and your interaction with the Platform and other third-party sites and
              Apps, to infer your likely interests to provide you with more relevant advertising. This information tells us about
              websites you've visited, apps you've downloaded and purchases you have made so that we can predict what else
              might interest you in the future and assess how effective the advertising on our Platform is. We collect this
              information by the use of Cookies and similar technologies on our Platform and from similar information received
              from third parties who advertise on our Platform and whose sites/Apps you visit.</p>
          </div>
          <p><b>Technical Information we collect about you.</b> We collect certain information about the device you use to access the
            Platform, such as your IP address, user agent, mobile carrier, time zone settings, identifiers for advertising purposes,
            model of your device, the device system, network type, device IDs, your screen resolution and operating system,
            app and file names and types, keystroke patterns or rhythms, battery state, audio settings and connected audio
            devices. Where you log-in from multiple devices, we will be able to use your profile information to identify your
            activity across devices. We may also associate you with information collected from devices other than those you use
            to log-in to the Platform.</p>
          <p><b>Location.</b> We collect information about your approximate location, including location information based on your
            SIM card and/or IP address. With your permission, we may also collect precise location data (such as GPS).</p>
          <p><b>Find other users and invite your friends.</b> You can choose whether to find other users of the Platform using our 'Find
            Friends' function. This functionality allows you to see which of your friends from either your telephone's contact list
            or Facebook friends list is using the Platform and offers you the option to follow them. You can also use this function
            to invite your contacts to join you on the Platform. We will use the contact information you have for that person,
            either from within your telephone's contact list or Facebook friends list and give you the option to send them either
            an SMS, email or third party message (such as Whatsapp, Facebook (including Facebook Messenger) or Twitter)
            inviting them to view your profile.
          </p>
          <p><b>In-App purchases.</b> If you reside in certain jurisdictions that offer in-app coin purchases, please note the provisions
            of our Virtual Items Policy. Your purchase will be made via your Apple iTunes or Google Play account. We do not
            collect any financial or billing information from you in relation to such a transaction. Please review the relevant app
            store's terms and notices in respect of the handling of such data. So that we can credit your account with the correct
            value in coins, we keep a record of the purchases you make, the time at which you make those purchases and the
            amount spent. In addition to in-app coin purchases, if you choose to make other payment related transactions the
            Platform may collect payment information, including payment card numbers or other third-party payment
            information (such as PayPal) where required for the purpose of payment.</p>
          <p><b>Proof of your identity or age.</b> We sometimes ask you to provide proof of identity or age in order to use certain
            features, such as livestream or verified accounts, or when you apply for a Pro Account, ensure that you are old
            enough to use the Platform, or in other instances where verification may be required</p>
          <p>Cookies
          </p>
          <p>We and our vendors and service providers use cookies and other similar technologies (e.g., web beacons, flash
            cookies, etc.) (“Cookies”) to automatically collect information, measure and analyze which web pages you click on
            and how you use the Platform, enhance your experience using the Platform, improve our services, and provide you
            with targeted advertising on the Platform and elsewhere across your devices. Cookies enable the Platform to provide
            certain features and functionality. Web beacons are very small images or small pieces of data embedded in images,
            also known as “pixel tags” or “clear GIFs,” that can recognize Cookies, the time and date a page is viewed, a
            description of the page where the pixel tag is placed, and similar information from your computer or device. By using
            the Platform, you consent to our use of Cookies.</p>
          <p>Additionally, we allow our business partners, advertising networks, and other advertising vendors and service
            providers (including analytics vendors and service providers) to collect information about your online activities
            through Cookies. We link your contact or subscriber information with your activity on our Platform across your
            devices, using your email or other log-in or device information. These third parties may use this information to
            display advertisements on our Platform and elsewhere online tailored to your interests, preferences, and
            characteristics. We are not responsible for the privacy practices of these third parties, and the information practices
            of these third parties are not covered by this Privacy Policy.
          </p>
          <p>You may be able to refuse or disable Cookies by adjusting your browser settings. Because each browser is different,
            please consult the instructions provided by your browser. Please note that you may need to take additional steps to
            refuse or disable certain types of Cookies. For example, due to differences in how browsers and mobile apps
            function, you may need to take different steps to opt out of Cookies used for targeted advertising in a browser and
            to opt out of targeted advertising for a mobile application, which you may control through your device settings or
            mobile app permissions. In addition, your opt-out selection is specific to the particular browser or device that you
            are using when you opt out, so you may need to opt-out separately for each of browser or device. If you choose to
            refuse, disable, or delete Cookies, some of the functionality of the Platform may no longer be available to you.</p>
          <div>
            <p>How we use your personal data?</p>
            <p>We will use the information we collect about you in the following ways:</p>
            <ul>
              <li>notify you about changes to our service;
              </li>
              <li>provide you with user support;
              </li>
              <li>personalise the content you receive and provide you with tailored content that will be of interest to you;</li>
              <li>enable you to share User Content and interact with other users;</li>
              <li>enable our messenger service to function if you choose to use this function;</li>
              <li>enable you to participate in the virtual items program;</li>
              <li>communicate with you;</li>
              <li>to help us detect and combat abuse, harmful activity, fraud, spam, and illegal activity on the Platform;</li>
              <li>ensure your safety and security including reviewing user content, messages and associated metadata for
                breach of our Community Guidelines and other inappropriate content;</li>
              <li>ensure content is presented in the most effective manner for you and your device;</li>
              <li>improve, promote and develop the Platform and promote popular topics, hashtags and campaigns on the
                Platform;</li>
              <li>carry out data analysis and test the Platform to ensure its stability and security;</li>
              <li>allow you to participate in interactive features of the Platform;</li>
              <li>to enable you to socialise on the Platform, for example, by allowing other users to identify you via the "Find
                other friends" function or through their phone contacts;</li>
              <li>to ensure that you are old enough to use our Platform (as required by law).</li>
              <li>provide you with personalised advertising;</li>
              <li>provide you with location-based services (where those services are available in your jurisdiction);</li>
              <li>To inform our algorithms</li>
              <li>enforce our terms, conditions and policies;</li>
              <li>administer the Platform including troubleshooting; and</li>
              <li>to facilitate sales, promotion, and purchase of goods and services and to provide user support</li>
            </ul>
          </div>
          <div>
            <p>How we share your personal data</p>
            <p>We share your data with the following selected third parties:</p>
            <u>Business Partners</u>
            <p>If you choose to register to use the Platform using your social network account details (e.g., Facebook, Twitter,
              Instagram, Google), you will provide us or allow your social network to provide us with your phone number, email
              address, username and public profile. We will likewise share certain information with the relevant social network
              such as your app ID, access token and the referring URL. If you choose to allow a third-party service to access your
              account, we will share certain information about you with the third party. Depending on the permissions you grant,
              the third party may be able to obtain your account information and other information you choose to provide.<br />
              Where you opt to share content on social media platforms, the video, username and accompanying text will be
              shared on that platform or, in the case of sharing via instant messaging platforms such as Whatsapp, a link to the
              content will be shared.</p>
            <u>Payment Providers</u>
            <p>If you choose to Buy Coins or conduct other payment related transactions, we will share data with the relevant
              payment provider to facilitate this transaction. For Coin transactions, we share a transaction ID to enable us to
              identify you and credit your account with the correct value in coins once you have made the payment.</p>
            <u>Service Providers</u>
            <p>
              We provide information and content to service providers who support our business, such as cloud service providers
              and providers of content moderation services to ensure that the Platform is a safe and enjoyable place and service
              providers that assist us in marketing the Platform.
            </p>
            <u>Analytics providers</u>
            <p>We use analytics providers to help us in the optimisation and improvement of the Platform. Our third-party analytics
              providers also help us serve targeted adverts.</p>
            <u>Advertisers and Advertising Networks</u>
            <p>
              We share information with advertisers and third-party measurement companies to show how many and which users
              of the Platform have viewed or clicked on an advertisement. We share your device ID with measurement companies
              so that we can link your activity on the Platform with your activity on other websites; we then use this information
              to show you adverts which may be of interest to you.
            </p>
            <u>Our Corporate Group</u>
            <p>We may also share your information with other members, subsidiaries, or affiliates of our corporate group, to
              provide the Platform including improving and optimising the Platform, preventing illegal use and supporting users.
            </p>
            <div>
              <u>Law Enforcement</u>
              <p>We will share your information with law enforcement agencies, public authorities or other organisations if legally
                required to do so, or if such use is reasonably necessary to:</p>
              <ul>
                <li>comply with legal obligation, process or request;</li>
                <li>enforce our Terms of Service and other agreements, policies, and standards, including investigation of any
                  potential violation thereof;</li>
                <li>detect, prevent or otherwise address security, fraud or technical issues; or</li>
                <li>protect the rights, property or safety of us, our users, a third party or the public as required or permitted
                  by law (including exchanging information with other companies and organisations for the purposes of fraud
                  protection and credit risk reduction).</li>
              </ul>
            </div>
            <u>Public Profiles</u>
            <p>Please note that if your profile is public, your content will be visible to anyone on the Platform and may also be
              accessed or shared by your friends and followers as well as third parties such as search engines, content aggregators
              and news sites. You can change who can see a video each time you upload a video. Alternatively, you can change
              your profile to default private by changing your settings to 'Private Account' in “Manage my account” settings.</p>
            <div>
              <u>Sale or Merger</u>
              <p>We will also disclose your information to third parties:</p>
              <ul>
                <li>in the event that we sell or buy any business or assets (whether a result of liquidation, bankruptcy or
                  otherwise), in which case we will disclose your data to the prospective seller or buyer of such business or
                  assets; or</li>
                <li>if we sell, buy, merge, are acquired by, or partner with other companies or businesses, or sell some or all of
                  our assets. In such transactions, user information may be among the transferred assets.</li>
              </ul>
              <p>Where we store your personal data<br />
                The personal data we collect from you may be stored on a server located inside or outside Pakistan. We maintain
                major servers around the world to bring you our services continuously.<br />
                Your Rights and Choices<br />
                You can access and edit most of your profile information by signing into platform. You can delete the User Content
                you uploaded. We also provide a number of tools in Settings that allow you to control, among others, who can view
                your videos, send you messages, or post comments to your videos. Should you choose to do so, you may delete your
                entire account in Settings. You may also be afforded certain rights under applicable laws, which may include the
                right to access, delete, update, or rectify your data, to be informed of the processing of your data, to file complaints
                with authorities, and potentially other rights. If you have any questions on how to use the above tools, want to know
                about what rights you may have or want to exercise them, or have any requests, inquiries, or complaints, please
                contact us at: <b>corporate.affairs@symmetrygroup.biz</b>.<br />
                The security of your personal data<br />
                We take steps to ensure that your information is treated securely and in accordance with this policy. Unfortunately,
                the transmission of information via the internet is not completely secure. Although we will protect your personal
                data, for example, by encryption, we cannot guarantee the security of your information transmitted via the Platform;
                any transmission is at your own risk.<br />
                We have appropriate technical and organizational measures to ensure a level of security appropriate to the risk of
                varying likelihood and severity for the rights and freedoms of you and other users. We maintain these technical and
                organizational measures and will amend them from time to time to improve the overall security of our systems.<br />
                We will, from time to time, include links to and from the websites of our partner networks, advertisers and affiliates.
                If you follow a link to any of these websites, please note that these websites have their own privacy policies and that
                we do not accept any responsibility or liability for these policies. Please check these policies before you submit any
                information to these websites.<br />
                How long we keep your personal data<br />
                We retain your information for as long as it is necessary, for example, to comply with our contractual relationship or
                to provide you with our service. Where we do not need your information in order to provide the service to you, we
                retain it only for so long as we have a legitimate business purpose in keeping such data, as may be allowed by
                applicable laws. However, there are occasions where we are likely to keep this data for longer in accordance with
                our legal obligations or where it is necessary for the establishment, exercise or defense of legal claims.<br />
                After you have terminated your use of our Platform, we store your information in an aggregated and anonymised
                format.<br />
                Information relating to children<br />
                The platform is not directed at children under the age of 13. In certain cases this age may be higher due to local
                regulatory requirements, please see your local privacy policy for more information. If you believe that we have
                personal data about or collected from a child under the relevant age, contact us
                at: <b>corporate.affairs@symmetrygroup.biz</b>.<br />
                Complaints<br />
                In the event that you wish to make a complaint about how we process your personal data, contact us immediately
                at: <b>corporate.affairs@symmetrygroup.biz</b>. We will endeavor to deal with your request as soon as possible. This is
                without prejudice to your right to launch a claim with the relevant data protection authority.<br />
                Changes<br />
                We may update this Privacy Policy from time to time. When we update the Privacy Policy, we will notify you by
                updating the “Last Updated” date at the top of this policy and posting the new Privacy Policy and providing any other
                notice required by applicable law. Your continued access to or use of the Platform after the date of the updated
                policy constitutes your acceptance of the updated policy. If you do not agree to the updated policy, you must stop
                accessing or using the Platform.<br />
                Contact<br />
                If you have questions, comments, or requests regarding this policy, contact us at:<br />
                <b>corporate.affairs@symmetrygroup.biz</b>
              </p>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}