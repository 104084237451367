import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Login from './components/Login';
import Register from './components/Register';
import Forget from './components/Forget';
import Welcome from './components/Welcome';
import Profile from "./components/Profile";
import TermsofServices from './components/TermsofServices';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/forget" element={<Forget />} />
        <Route exact path="/app-welcome" element={<Welcome />} />
        <Route exact path="/terms-of-services" element={<TermsofServices />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/profile/:userName" element={<Profile />} />
      </Routes>
    </Router>
  );
}

export default App;
