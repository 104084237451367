import React, { useState } from 'react'
import { Routes, Route, Link } from "react-router-dom"
import Axios from 'axios'

function Forget() {
    const [email , setemail] = useState("");
    const forgotPassword = (e) => {
        e.preventDefault();
        Axios.post("http://localhost:3001/forgotpassword",{
            email : email,
        }).then((response) => {
            if(response.data){
                console.log(response.data.message);
            }else if(response.data.err){
                console.log("Invalid Request");
            }
        });
    };

    return (
        <>
            <div class="fixed-background"></div>
            <main>
            <div className="container mt-5">
                <div className="row h-100">
                    <div className="col-12 col-md-10 mx-auto my-auto">
                        <div className="card auth-card">
                            <div className="position-relative image-side ">
                                <p className=" text-white h2">MAGIC IS IN THE DETAILS</p>
                                <p className="white mb-0">
                                    Do you remember your password.
                                    <br />Go , please 
                                    <Link to="/" className="white"> Login</Link>.
                                </p>
                            </div>
                            <div className="form-side">
                                <a href="#">
                                    <span className="logo-single" />
                                </a>
                                <h6 className="mb-4">Forget</h6>
                                <form>
                                    <label className="form-group has-float-label mb-4">
                                        <input className="form-control" type="text" onChange={(e) => {setemail(e.target.value)}} />
                                        <span>E-mail</span>
                                    </label>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <Link to="/"></Link>
                                        <button className="btn btn-primary btn-lg btn-shadow" type="submit" onClick={forgotPassword}>RESET</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        </>
    );
}


export default Forget;
