import React from 'react'

export default function Posts({ posts }) {
  return (
    <>
        { 
            posts && posts?.map((row, index) => (
                <div className="col-xs-6 col-lg-3 col-12 mb-4" key={index}>
                    <div className="card">
                        <div className="position-relative">
                        { row.media_url && row.media_url.includes('video') ?
                            <video controls style={{width: "320px", height: "320px"}}>
                                <source src={row.media_url} type="video/mp4"/>
                            </video> 
                            : <img className="card-img-top" src={row.media_url} alt="Post Picture" /> }
                        </div>
                        <div className="card-body">
                            <p className="list-item-heading mb-4"> 
                                <a href={"https://www.instagram.com/"}  target="_blank"></a>
                            </p>
                            <footer>
                                <p className=" text-small mb-0 font-weight-light"><i className='glyph-icon simple-icon-like'></i> {row.like_count}  
                                    <b className='float-right'>Likes</b> </p>
                                <p className=" text-small mb-0 font-weight-light"><i className='glyph-icon iconsminds-speach-bubble-3'></i> {row.comments_count}  
                                    <b className='float-right'>Comments</b> </p>
                            </footer>
                        </div>
                    </div>
                </div>
            ))
        }
    </>
  )
}
