import React from 'react';
import Topnav from '../common/Header';
import Sidebar from '../common/Sidebar';
import Footer from '../common/Footer';
import YoutubePopularChannels from '../common/YoutubePopularChannels';
import Search from '../common/Search';

export default function Welcome() {
  return (
    <>
      <Topnav />
      <Sidebar />
      <main>
        <Search/>
        <hr></hr>
        <YoutubePopularChannels/>
      </main>
      <Footer />
    </>
  )
}
