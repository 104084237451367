import React from 'react'
import {Link} from "react-router-dom";

export default function Sidebar() {
    return (
        <div className="menu">
            <div className="main-menu">
                <div className="scroll">
                    <ul className="list-unstyled">
                        <li className='active'>
                            <Link  to={`/app-welcome`}>
                                <i className="glyph-icon simple-icon-magnifier" />
                                <span>Search</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/favorites`}>
                                <i className="glyph-icon simple-icon-star" />
                                <span>Favorite List</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/terms-of-services`}>
                                <i className="glyph-icon simple-icon-envelope" />
                                <span>Terms of Service</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/privacy-policy`}>
                                <i className="glyph-icon simple-icon-list" />
                                <span>Privacy Policy</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}