import React, { useState } from 'react';
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import Axios from 'axios';

function Register() {
    const navigate = useNavigate();
    const [usernameReg , setusernameReg] = useState("");
    const [emailReg , setemailReg] = useState("");
    const [passwordReg , setpasswordReg] = useState("");
    const register = (e) => {
        e.preventDefault();
        Axios.post("http://localhost:3001/submit",{
            username : usernameReg,
            email : emailReg,
            password : passwordReg,
        }).then((response) => {
            console.log(response);
            if(response.data.affectedRows > 0){
                navigate('/',{ replace: true });
            }else if(response.data.err){
                console.log('Error while registration');
            }
        });
    };

    return (
        <>
            <div class="fixed-background"></div>
            <main>
            <div className="container mt-5">
                <div className="row h-100">
                    <div className="col-12 col-md-10 mx-auto my-auto">
                        <div className="card auth-card">
                            <div className="position-relative image-side ">
                                <p className=" text-white h2">MAGIC IS IN THE DETAILS</p>
                                <p className="white mb-0">
                                    We are welcoming you to become a member.
                                    <br />If you already have an account, please 
                                    <Link to="/" className="white"> login</Link>.
                                </p>
                            </div>
                            <div className="form-side">
                                <a href="#">
                                    <span className="logo-single" />
                                </a>
                                <h6 className="mb-4">Register</h6>
                                <form>
                                    <label className="form-group has-float-label mb-4">
                                        <input className="form-control" type="text" onChange={(e) => {setusernameReg(e.target.value)}}/>
                                        <span>Name</span>
                                    </label>
                                    <label className="form-group has-float-label mb-4">
                                        <input className="form-control" type="email" onChange={(e) => {setemailReg(e.target.value)}}/>
                                        <span>E-mail</span>
                                    </label>
                                    <label className="form-group has-float-label mb-4">
                                        <input className="form-control" type="password" onChange={(e) => {setpasswordReg(e.target.value)}} />
                                        <span>Password</span>
                                    </label>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <Link to="/forget"></Link>
                                        <button className="btn btn-primary btn-lg btn-shadow" type="submit" onClick={register}>REGISTER</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        </>
    );
}


export default Register;
